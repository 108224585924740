import { Link } from "react-router-dom";
import useFetch from "../../../Component/Hooks/useFetch";
import Skeleton from "../Loader/Skeleton";
import FirstStats from "./Chart/FirstStats";
import TaxiChart from "./Chart/TaxiChart";
import DemenageurChart from "./Chart/DemenageurChart";
import { useEffect, useState } from "react";
import axios from "axios";

const AdminDashboard = () => {
  const [demenageurWidgets, setDemenageurWidgets] = useState([]);

  const { loading, data } = useFetch(
    `${process.env.REACT_APP_API_URL}/api/widgets/taxi`
  );

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/widgets/demenageur`)
      .then((response) => setDemenageurWidgets(response.data))
      .catch((error) =>
        console.error("Erreur lors de la récupération des widgets :", error)
      );
  }, []);

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();
  const currentQuarter = Math.floor((currentMonth - 1) / 3) + 1;

  // Fonction pour filtrer les widgets selon une période donnée
  const filterWidgetsByDate = (widgets, startDate, endDate) =>
    widgets?.filter((widget) => {
      const widgetDate = new Date(widget.createdAt);
      return widgetDate >= startDate && widgetDate <= endDate;
    })?.length || 0;

  // Widgets Taxi
  const totalTaxiWidgets = data?.widgets?.length || 0;
  const totalTaxiPrice = totalTaxiWidgets * 10;

  // Widgets Déménageurs
  const totalDemenageurWidgets = demenageurWidgets?.widgets?.length || 0;
  const totalDemenageurPrice = totalDemenageurWidgets * 10;

  // Total combiné
  const totalCombinedWidgets = totalTaxiWidgets + totalDemenageurWidgets;
  const totalCombinedPrice = totalTaxiPrice + totalDemenageurPrice;

  // Trimestre actuel et précédent
  const quarterStartDate = new Date(currentYear, (currentQuarter - 1) * 3, 1);
  const quarterEndDate = new Date(currentYear, currentQuarter * 3, 0);

  const previousQuarter = currentQuarter === 1 ? 4 : currentQuarter - 1;
  const previousQuarterYear =
    currentQuarter === 1 ? currentYear - 1 : currentYear;
  const prevQuarterStartDate = new Date(
    previousQuarterYear,
    (previousQuarter - 1) * 3,
    1
  );
  const prevQuarterEndDate = new Date(
    previousQuarterYear,
    previousQuarter * 3,
    0
  );

  // Widgets par trimestre
  const quarterlyTaxiWidgetsCount = filterWidgetsByDate(
    data?.widgets || [],
    quarterStartDate,
    quarterEndDate
  );
  const previousQuarterTaxiWidgetsCount = filterWidgetsByDate(
    data?.widgets || [],
    prevQuarterStartDate,
    prevQuarterEndDate
  );

  const quarterlyDemenageurWidgetsCount = filterWidgetsByDate(
    demenageurWidgets?.widgets || [],
    quarterStartDate,
    quarterEndDate
  );
  const previousQuarterDemenageurWidgetsCount = filterWidgetsByDate(
    demenageurWidgets?.widgets || [],
    prevQuarterStartDate,
    prevQuarterEndDate
  );

  // Total combiné pour les trimestres
  const quarterlyCombinedWidgetsCount =
    quarterlyTaxiWidgetsCount + quarterlyDemenageurWidgetsCount;
  const previousQuarterCombinedWidgetsCount =
    previousQuarterTaxiWidgetsCount + previousQuarterDemenageurWidgetsCount;

  // Noms des trimestres
  const quarterNames = [
    "Premier trimestre",
    "Deuxième trimestre",
    "Troisième trimestre",
    "Quatrième trimestre",
  ];
  const currentQuarterName = quarterNames[currentQuarter - 1];
  const previousQuarterName = quarterNames[previousQuarter - 1];

  return (
    <div className="w-full bg-gray-200">
      <div className="flex justify-between items-center mx-5">
        <div className="font-bold tracking-tight text-gray-900 sm:text-5xl md:text-2xl p-6">
          <h1 className="block md:text-4xl">
            Admin
            <span className="text-transparent bg-clip-text bg-gradient-to-tr to-cyan-500 from-blue-600 ml-2">
              Dashboard
            </span>
          </h1>
          <h2>Statistiques d'activation</h2>
        </div>
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
          <Link to="/createUser">Créer un utilisateur</Link>
        </button>
      </div>
      {loading ? (
        <Skeleton />
      ) : (
        <div className="flex flex-col gap-6">
          <FirstStats
            totalWidgets={totalCombinedWidgets}
            totalPrice={totalCombinedPrice}
            quarterlyWidgetsCount={quarterlyCombinedWidgetsCount}
            currentQuarterName={currentQuarterName}
            previousQuarterWidgetsCount={previousQuarterCombinedWidgetsCount}
            previousQuarterName={previousQuarterName}
          />
          <TaxiChart
            totalWidgets={totalTaxiWidgets}
            totalPrice={totalTaxiPrice}
            quarterlyWidgetsCount={quarterlyTaxiWidgetsCount}
            totalTaxiWidgets={totalTaxiWidgets}
            taxiList={data?.widgets || []}
          />
          <DemenageurChart
            totalWidgets={totalDemenageurWidgets}
            totalPrice={totalDemenageurPrice}
            quarterlyWidgetsCount={quarterlyDemenageurWidgetsCount}
            previousQuarterWidgetsCount={previousQuarterDemenageurWidgetsCount}
            currentQuarterName={currentQuarterName}
            previousQuarterName={previousQuarterName}
          />
        </div>
      )}
    </div>
  );
};

export default AdminDashboard;
