import React, { useEffect, useState } from "react";
import MiniMap from "./MiniMap";
import axios from "axios";
import {
  PhoneArrowUpRightIcon,
  EnvelopeIcon,
  CurrencyEuroIcon,
  MapPinIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid";
import { Tooltip } from "@material-tailwind/react";
import AdressInput from "./AdressInput";
import moment from "moment";
import AddressInput from "./AdressInput";

const SimulateurTaxi = ({ widgetId }) => {
  const [widgetData, setWidgetData] = useState({
    startAddress: "",
    endAddress: "",
    passengers: 1,
    baggage: 0,
    allerRetourIsActive: true,
  });

  const [calculatedPrice, setCalculatedPrice] = useState(null);
  const [distance, setDistance] = useState(null);
  const [isRoundTrip, setIsRoundTrip] = useState(false);
  const [widgets, setWidgets] = useState(null);

  const [startCoordinates, setStartCoordinates] = useState(null);
  const [endCoordinates, setEndCoordinates] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [showEditButton, setShowEditButton] = useState(false);
  const [fieldError, setFieldError] = useState(false);

  useEffect(() => {
    fetchWidget();
  }, [widgetId]);

  const fetchWidget = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/widgets/taxi/getOneWidget/${widgetId}`
      );

      if (response.status === 200) {
        const data = response.data;
        setWidgets(data.widget);
      } else {
        console.error("Erreur lors de la récupération des widgets");
      }
    } catch (error) {
      if (
        (error.response && error.response.status === 404) ||
        error.response.status === 500
      ) {
        setIsError(true);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "departureTime") {
      const parsedDate = new Date(value);
      const offsetInMinutes = parsedDate.getTimezoneOffset();
      parsedDate.setMinutes(parsedDate.getMinutes() - offsetInMinutes);
      const formattedDate = parsedDate.toISOString().slice(0, 16);
      setWidgetData((prevData) => ({
        ...prevData,
        departureTime: formattedDate,
      }));
    } else {
      setWidgetData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleAddressChange = (e, fieldName) => {
    const query = e.target.value.toLowerCase(); // Convertir la saisie en minuscule pour comparer facilement

    // Détecter si les mots "aéroport" et "nice" sont présents
    if (
      (query.includes("aéroport") || query.includes("aeroport")) &&
      query.includes("nice")
    ) {
      // Si oui, définir l'adresse et les coordonnées fixes
      const fixedAddress = "Rue Costes et Bellonte, Nice";
      const coordinates = { latitude: 43.6591, longitude: 7.2158 }; // Coordonnées de l'aéroport de Nice

      setWidgetData((prevData) => ({
        ...prevData,
        [fieldName]: fixedAddress,
      }));

      // Mise à jour des coordonnées
      if (fieldName === "startAddress") {
        setStartCoordinates(coordinates);
      } else if (fieldName === "endAddress") {
        setEndCoordinates(coordinates);
      }
    } else {
      // Si les mots-clés ne sont pas présents, mise à jour normale
      setWidgetData((prevData) => ({
        ...prevData,
        [fieldName]: e.target.value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/widgets/taxi/simulate/${widgetId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...widgetData, isRoundTrip }),
        }
      );

      if (response.ok) {
        const { distance, price, startCoordinatesData, endCoordinatesData } =
          await response.json();

        setStartCoordinates(startCoordinatesData);
        setEndCoordinates(endCoordinatesData);
        fetchWidget();

        let calculatedPrice = price;
        let calculatedDistance = distance;
        if (isRoundTrip) {
          calculatedPrice *= 2;
          calculatedDistance *= 2;
        }
        setCalculatedPrice(calculatedPrice);
        setDistance(calculatedDistance);
        setShowForm(false);
        setShowEditButton(true);
      } else {
        console.error("Erreur lors de la simulation");
        setFieldError(true);
      }
    } catch (error) {
      console.error("Erreur:", error);
      setFieldError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const createEmailContent = () => {
    const emailSubject = "Demande de réservation de course - Simulateur";
    const emailBody = `
      Bonjour,
  
      Je viens de simuler une course sur votre site et je souhaite obtenir plus d'informations, ainsi qu'une validation du tarif, pour le trajet suivant :
  
      Aller simple ou aller-retour : ${
        isRoundTrip ? "Aller-retour" : "Aller simple"
      }
      Nombre de passagers : ${widgetData?.passengers}
      Nombre de bagages : ${widgetData?.baggage}
      Adresse de départ : ${widgetData?.startAddress}
      Adresse d'arrivée : ${widgetData?.endAddress}
      Date et heure souhaitées : ${moment
        .utc(widgetData?.departureTime)
        .format("DD-MM-YYYY HH:mm")}
        
      Prix de la course estimé : ${calculatedPrice} €
      Km de la course : ${distance} km
    

      Voici mes coordonnées de contact :

      Nom : (A remplir)
      Prénom : (A remplir)
      Téléphone : (+33) (A remplir)
      E-Mail: (A remplir)

      Dans l'attente de votre réponse,
      Cordialement,

      Demande de devis obtenue grâce à votre simulateur de course Local.fr
    `;

    return `mailto:${widgets?.contactMail}?subject=${encodeURIComponent(
      emailSubject
    )}&body=${encodeURIComponent(emailBody)}`;
  };

  const handleEditSimulation = () => {
    setShowForm(true);
    setShowEditButton(false);
  };

  const recordButtonClick = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/widgets/taxi/recordClick/${widgetId}`,
        { clickedAt: new Date() }
      );
    } catch (error) {
      console.error("Erreur lors de l'enregistrement du clic :", error);
    }
  };

  return (
    <>
      {isError ? (
        <div className="flex items-center justify-center h-screen w-screen antialiased text-center bg-stone-100 text-gray-800 font-openSans">
          <article className="mx-auto max-w-screen-md p-12 items-center">
            <h3 className="text-3xl font-light mb-12">
              Le simulateur est désactivé !
            </h3>
            <div>
              <p className="text-xl text-gray-800 mb-8">
                Merci de contacter notre support{" "}
                <a
                  className="text-blue-800 hover:text-blue-700 mb-8"
                  href="https://local.fr"
                >
                  Local.fr
                </a>{" "}
                pour plus d'informations.
              </p>
              <p className="font-bold">Simulateur Taxi-VTC avec Local.fr</p>
            </div>
          </article>
        </div>
      ) : (
        <div
          className="p-4 flex gap-4 justify-around flex-wrap"
          style={{
            backgroundColor: widgets?.backgroundColor,
            color: widgets?.labelColor,
          }}
        >
          {showForm ? (
            <form onSubmit={handleSubmit} className="space-y-4 w-full">
              <p className="text-2xl font-bold mb-4">Simulateur de course</p>
              <AddressInput
                label="Adresse de départ"
                color={widgets}
                value={widgetData.startAddress}
                onChange={(e) => handleAddressChange(e, "startAddress")}
                setFieldValue={(value) =>
                  setWidgetData((prev) => ({ ...prev, startAddress: value }))
                }
              />
              <AddressInput
                label="Adresse d'arrivée"
                color={widgets}
                value={widgetData.endAddress}
                onChange={(e) => handleAddressChange(e, "endAddress")}
                setFieldValue={(value) =>
                  setWidgetData((prev) => ({ ...prev, endAddress: value }))
                }
              />
              <div>
                <label
                  htmlFor="departureTime"
                  className="mb-1 text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-black last:mr-0 mr-1"
                  style={{
                    backgroundColor: widgets?.btnColor,
                    color: widgets?.labelColor,
                  }}
                >
                  Date et heure de départ *
                </label>
                <input
                  type="datetime-local"
                  id="departureTime"
                  name="departureTime"
                  value={widgetData.departureTime}
                  onChange={handleInputChange}
                  className="w-full border rounded-md px-3 py-2 text-gray-800"
                  required
                />
              </div>

              <div>
                <label
                  htmlFor="passengers"
                  className="mb-1 text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-black last:mr-0 mr-1"
                  style={{
                    backgroundColor: widgets?.btnColor,
                    color: widgets?.labelColor,
                  }}
                >
                  Nombre de passagers *
                </label>
                <select
                  id="passengers"
                  name="passengers"
                  value={widgetData.passengers}
                  onChange={handleInputChange}
                  className="w-full border rounded-md px-3 py-2 text-gray-800"
                  required
                >
                  {[...Array(10).keys()].map((num) => (
                    <option key={num + 1} value={num + 1}>
                      {num + 1}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label
                  htmlFor="baggage"
                  className="mb-1 text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-black last:mr-0 mr-1"
                  style={{
                    backgroundColor: widgets?.btnColor,
                    color: widgets?.labelColor,
                  }}
                >
                  Nombre de bagages *
                </label>
                <select
                  id="baggage"
                  name="baggage"
                  value={widgetData.baggage}
                  onChange={handleInputChange}
                  className="w-full border rounded-md px-3 py-2 text-gray-800"
                  required
                >
                  {[...Array(11).keys()].map((num) => (
                    <option key={num} value={num}>
                      {num}
                    </option>
                  ))}
                </select>
              </div>
              {widgets?.allerRetourIsActive ? (
                <div className="flex justify-between">
                  <label
                    htmlFor="isRoundTrip"
                    className="mb-1 text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-black last:mr-0 mr-1"
                    style={{
                      backgroundColor: widgets?.btnColor,
                      color: widgets?.labelColor,
                    }}
                  >
                    Aller retour ? *
                  </label>
                  <input
                    type="checkbox"
                    id="isRoundTrip"
                    name="isRoundTrip"
                    checked={isRoundTrip}
                    onChange={() => setIsRoundTrip(!isRoundTrip)}
                    className="peer sr-only opacity-0"
                  />
                  <label
                    htmlFor="isRoundTrip"
                    className="relative flex h-6 w-11 cursor-pointer items-center rounded-full bg-gray-400 px-0.5 outline-gray-400 transition-colors before:h-5 before:w-5 before:rounded-full before:bg-white before:shadow before:transition-transform before:duration-300 peer-checked:bg-green-500 peer-checked:before:translate-x-full peer-focus-visible:outline peer-focus-visible:outline-offset-2 peer-focus-visible:outline-gray-400 peer-checked:peer-focus-visible:outline-green-500"
                  >
                    <span className="sr-only">Enable</span>
                  </label>
                </div>
              ) : null}

              <button
                className=" text-white px-4 py-2 rounded-md  w-full"
                style={{
                  backgroundColor: widgets?.btnColor || "#000000",
                  color: widgets?.labelColor || "#ffffff",
                }}
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "Calcul en cours..." : "Calculer le prix"}
              </button>
            </form>
          ) : (
            <>
              {calculatedPrice !== null && (
                <div className="flex-col w-full">
                  <div className="mt-4">
                    <MiniMap
                      startLatitude={endCoordinates.endCoordinates.latitude}
                      startLongitude={endCoordinates.endCoordinates.longitude}
                      endLatitude={startCoordinates.startCoordinates.latitude}
                      endLongitude={startCoordinates.startCoordinates.longitude}
                    />
                  </div>

                  <div className="relative overflow-x-auto mt-5 shadow">
                    <table className="w-full text-sm text-left rtl:text-right  ">
                      <thead
                        className="text-sm uppercase text-white"
                        style={{
                          backgroundColor: widgets?.btnColor || "#000000",
                          color: widgets?.labelColor || "#ffffff",
                        }}
                      >
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            Tarif de la course
                          </th>
                          <th scope="col" className="px-6 py-3"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="bg-white hover:bg-gray-100">
                          <th
                            scope="row"
                            className="px-6 py-3 flex gap-2 whitespace-nowrap items-center text-sm font-semibold uppercase text-gray-800"
                          >
                            <MapPinIcon className="h-4 w-4 text-gray-800" />{" "}
                            Distance totale :
                          </th>
                          <td className="px-6 py-2 price_sim font-semibold text-sm text-gray-800 text-center">
                            {distance} km
                          </td>
                        </tr>
                        <tr className="bg-white hover:bg-gray-100 text-gray-800">
                          <th
                            scope="row"
                            className="px-6 py-2 flex gap-2 whitespace-nowrap items-center text-sm font-semibold uppercase"
                          >
                            <CurrencyEuroIcon className="h-4 w-4" /> Prix total
                            :
                          </th>

                          <td className="px-6 py-2 price_sim font-semibold text-sm text-center">
                            <div className="flex gap-4 items-center justify-center">
                              <p> {calculatedPrice} €</p>
                              <Tooltip content="Le prix reste approximatif, merci de prendre contact pour plus d’informations">
                                <InformationCircleIcon className="h-4 w-4" />
                              </Tooltip>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="totalPrice mt-2 flex gap-4">
                    <div className="contact flex gap-5 w-full  items-center justify-center mt-6">
                      <a
                        href={createEmailContent()}
                        onClick={recordButtonClick}
                      >
                        <button
                          className="text-white px-4 py-2 rounded-md shadow-lg my-1 flex items-center gap-2 transition ease-in-out delay-150 hover:scale-110 duration-300"
                          style={{
                            backgroundColor: widgets?.btnColor || "#000000",
                            color: widgets?.labelColor || "#ffffff",
                          }}
                        >
                          <EnvelopeIcon className="h-4 w-4" />
                          Réserver par mail
                        </button>
                      </a>
                      <a href={"tel:" + widgets?.contactPhone}>
                        <button
                          className="text-white px-4 py-2 rounded-md shadow-lg my-1  flex items-center gap-2 transition ease-in-out delay-150 hover:scale-110 duration-300"
                          style={{
                            backgroundColor: widgets?.btnColor || "#000000",
                            color: widgets?.labelColor || "#ffffff",
                          }}
                        >
                          <PhoneArrowUpRightIcon className="h-4 w-4" />
                          Réserver par téléphone
                        </button>
                      </a>
                      {showEditButton && (
                        <button
                          onClick={handleEditSimulation}
                          style={{
                            color: widgets?.btnColor || "#000000",
                          }}
                        >
                          Modifier la simulation
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default SimulateurTaxi;
