import React, { useState } from "react";
import Modal from "../../../../Component/Modal/Modal";
import TaxiListThreeMonth from "./TaxiListThreeMonth";

const TaxiChart = ({
  totalWidgets,
  totalPrice,
  monthlyWidgets,
  totalTaxiWidgets,
  taxiList,
  quarterlyWidgetsCount,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className=" w-full">
      <h2 className="px-8 font-bold tracking-tight text-gray-900 sm:text-5xl md:text-2xl">
        Widgets Taxi/VTC :
      </h2>
      <div className="grid gap-4 lg:gap-8 md:grid-cols-3 px-8 pt-5">
        <div className="relative p-6 rounded-2xl bg-white shadow dark:bg-gray-800">
          <div className="space-y-2">
            <div className="flex items-center space-x-2 rtl:space-x-reverse text-sm font-medium text-gray-500 dark:text-gray-200">
              <span>Revenu total Taxi</span>
            </div>

            <div className="text-3xl">{totalPrice}€</div>

            <div className="flex items-center space-x-1 rtl:space-x-reverse text-sm font-medium text-green-600">
              <span>{totalWidgets} widgets au total</span>

              <svg
                className="w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12 7a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0V8.414l-4.293 4.293a1 1 0 01-1.414 0L8 10.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0L11 10.586 14.586 7H12z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
          </div>
        </div>

        <div className="relative p-6 rounded-2xl bg-white shadow dark:bg-gray-800">
          <div className="space-y-2">
            <div className="flex items-center space-x-2 rtl:space-x-reverse text-sm font-medium text-gray-500 dark:text-gray-200">
              <span>Total widgets taxi</span>
            </div>

            <div className="text-3xl">{totalTaxiWidgets}</div>

            <div className="flex items-center space-x-1 rtl:space-x-reverse text-sm font-medium text-green-600">
              <span>En production</span>
            </div>
          </div>
        </div>

        <div className="relative p-6 rounded-2xl bg-white shadow dark:bg-gray-800">
          <div className="space-y-2">
            <div className="flex items-center space-x-2 rtl:space-x-reverse text-sm font-medium text-gray-500 dark:text-gray-200">
              <span>Widgets activés ces 3 derniers mois</span>
            </div>

            <div className="text-3xl">{quarterlyWidgetsCount}</div>

            <div className="flex items-center space-x-1 rtl:space-x-reverse text-sm font-medium text-green-600 justify-between">
              <span> {quarterlyWidgetsCount * 10} €</span>
              <button
                onClick={toggleModal}
                className="bg-gray-800 rounded px-2 text-white hover:bg-gray-600"
              >
                Voir la liste
              </button>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={toggleModal}
          children={<TaxiListThreeMonth taxisList={taxiList} />}
        />
      )}
    </div>
  );
};

export default TaxiChart;
