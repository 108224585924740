import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import useDebounce from "../../../Component/Hooks/useDebounce"; // Hook de délai
import { XMarkIcon, MagnifyingGlassIcon } from "@heroicons/react/24/solid"; // Icônes pour UI

const MAX_ADDRESS_SUGGESTIONS = 5;

const AddressInput = ({
  label,
  value,
  onChange,
  color,
  fieldError,
  setFieldValue,
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false); // Gère l'affichage de la popup
  const [searchQuery, setSearchQuery] = useState(""); // Stocke la recherche dans la popup
  const debouncedValue = useDebounce(searchQuery, 300); // Déclenche la recherche après 300ms
  const inputRef = useRef(null);
  const popupRef = useRef(null);
  const overlayRef = useRef(null);

  useEffect(() => {
    if (!debouncedValue || debouncedValue.length < 3) {
      setSuggestions([]);
      return;
    }

    const niceAirportKeywords = [
      "aéroport nice",
      "aéroport de nice",
      "nice airport",
      "aéroport de nice côte d'azur",
    ];

    if (
      niceAirportKeywords.some((kw) =>
        debouncedValue.toLowerCase().includes(kw)
      )
    ) {
      const customAddress = {
        name: "19 Rue Costes et Bellonte, 06200 Nice",
      };
      setSuggestions([customAddress]);
      setIsSearching(false);
      return;
    }

    setIsSearching(true);

    axios
      .get(`${process.env.REACT_APP_API_URL}/api/widgets/taxi/geocode`, {
        params: { query: debouncedValue },
      })
      .then((response) => {
        setSuggestions(response.data);
      })
      .catch(() => {
        setSuggestions([]);
      })
      .finally(() => {
        setIsSearching(false);
      });
  }, [debouncedValue]);

  // 🏷️ Sélection d'une suggestion
  const handleSuggestionClick = (suggestion) => {
    setFieldValue(suggestion.name);
    setSearchQuery(suggestion.name);
    setIsPopupOpen(false); // Ferme la popup après sélection
  };

  // 🖱️ Gère le clic en dehors de la popup et de l'overlay pour fermer la fenêtre
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isPopupOpen &&
        overlayRef.current &&
        overlayRef.current.contains(event.target) &&
        popupRef.current &&
        !popupRef.current.contains(event.target)
      ) {
        setIsPopupOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPopupOpen]);

  // 🏷️ Filtrer les suggestions par catégorie
  const filteredSuggestions = {
    gares: suggestions.filter((s) => s.name.toLowerCase().includes("gare")),
    aeroports: suggestions.filter((s) =>
      s.name.toLowerCase().includes("aéroport")
    ),
    villes: suggestions.filter(
      (s) =>
        !s.name.toLowerCase().includes("gare") &&
        !s.name.toLowerCase().includes("aéroport")
    ),
  };

  return (
    <div className="relative" ref={inputRef}>
      <label
        htmlFor={label}
        className="mb-1 text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-black last:mr-0 mr-1"
        style={{
          backgroundColor: color?.btnColor,
          color: color?.labelColor,
        }}
      >
        {label} *
      </label>
      <input
        type="text"
        id={label}
        name={label}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        onFocus={() => setIsPopupOpen(true)}
        className={`w-full border rounded-md px-3 py-2 text-gray-800 ${
          isPopupOpen ? "cursor-default" : ""
        }`}
        required
        readOnly={isPopupOpen} // Empêche la modification quand la popup est ouverte
      />

      {fieldError && (
        <span className="text-red-300">Impossible de trouver l'adresse.</span>
      )}

      {/* Overlay noir semi-transparent */}
      {isPopupOpen && (
        <div
          ref={overlayRef}
          className="fixed inset-0 bg-black bg-opacity-50 z-10"
        />
      )}

      {/* 🏷️ Popup affichée quand on clique sur l'input */}
      {isPopupOpen && (
        <div
          ref={popupRef}
          className="fixed top-1/4 left-1/2 transform -translate-x-1/2 w-[80%] bg-white border border-gray-300 rounded-md shadow-lg p-4 z-20"
        >
          {/* Barre de recherche dans la popup */}
          <div className="flex items-center border-b pb-2 mb-2">
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-500 mr-2" />
            <input
              type="text"
              placeholder="Rechercher une adresse..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full border-none focus:outline-none text-gray-800"
            />
            <XMarkIcon
              className="h-5 w-5 text-gray-500 cursor-pointer"
              onClick={() => setIsPopupOpen(false)}
            />
          </div>

          {/* Suggestions classées par catégorie */}
          {Object.keys(filteredSuggestions).map((category) => (
            <div key={category} className="mb-3">
              {filteredSuggestions[category].length > 0 && (
                <>
                  <h3 className="text-xs uppercase font-semibold text-gray-600 mb-1 bg-gray-300 p-2 rounded-md">
                    {category === "gares"
                      ? "🚆 Gares"
                      : category === "aeroports"
                      ? "✈️ Aéroports"
                      : "🏙️ Villes"}
                  </h3>
                  <ul>
                    {filteredSuggestions[category]
                      .slice(0, MAX_ADDRESS_SUGGESTIONS)
                      .map((suggestion, index) => (
                        <li
                          key={index}
                          onClick={() => handleSuggestionClick(suggestion)}
                          className="px-2 py-2 cursor-pointer hover:bg-gray-200 truncate odd:bg-gray-100 even:bg-slate-100 text-gray-800 text-sm rounded-md"
                        >
                          {suggestion.name}
                        </li>
                      ))}
                  </ul>
                </>
              )}
            </div>
          ))}

          {/* Affichage si aucune suggestion trouvée */}
          {suggestions.length === 0 && !isSearching && (
            <p className="text-gray-500 text-sm text-center">
              Aucun résultat trouvé.
            </p>
          )}

          {/* Affichage du loader si en cours de recherche */}
          {isSearching && (
            <p className="text-gray-500 text-sm text-center">Recherche...</p>
          )}
        </div>
      )}
    </div>
  );
};

export default AddressInput;
